import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { StyledEngineProvider, Theme, ThemeProvider } from '@mui/material';
import jssPreset from '@mui/styles/jssPreset';
import StylesProvider from '@mui/styles/StylesProvider';
import { createBrowserHistory } from 'history';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { SnackbarProvider } from 'notistack';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { Router } from 'react-router-dom';
import GlobalStyles from 'src/components/GlobalStyles';
import ScrollReset from 'src/components/ScrollReset';
import { AuthProvider } from 'src/contexts/JWTAuthContext';
import routes, { renderRoutes } from 'src/routes';
import { getSubDomainChannelView, isIframe } from 'src/views/utils/Utils';
import { theme } from './theme';

declare module '@mui/styles/defaultTheme' {
    interface DefaultTheme extends Theme { }
}

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const history = createBrowserHistory();

const App: FC = () => {
    const [messages, setMessages] = useState<any>();
    var flatten = require('flat');
    const [loading, isLoading] = useState<boolean>(true);
    var user = JSON.parse(localStorage.getItem('user'))
    var lang = localStorage.getItem('language')

    async function loadLocaleData(locale: string) {
        switch (locale) {
            case 'en':
                return import('./compiled-lang/en.json')
            case 'ca':
                return import('./compiled-lang/cat.json')
            case 'es':
                return import('./compiled-lang/es.json')
            default:
                return import('./compiled-lang/en.json')
        }
    }

    async function getMessages() {
        user = JSON.parse(localStorage.getItem('user'));
        lang = localStorage.getItem('language');
        setMessages(await loadLocaleData((user && user.language && user.language.toLowerCase()) || lang || 'es'))
        isLoading(false);
    }

    useEffect(() => {
        window.addEventListener('userUpdated', getMessages);
        getMessages();
        return () => {
            window.removeEventListener('userUpdated', getMessages);
        };
    }, []);

    const subdomainchannel = getSubDomainChannelView();
    if (loading) return null;
    else {
        return (
            <StyledEngineProvider injectFirst>
                <IntlProvider
                    locale={(user && user.language) || lang || 'es'}
                    messages={flatten(messages)}
                >
                    <ThemeProvider theme={theme}>
                        <StylesProvider jss={jss}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <SnackbarProvider
                                    dense
                                    maxSnack={3}
                                >
                                    <Router history={history}>
                                        <AuthProvider>
                                            <GlobalStyles />
                                            <ScrollReset />
                                            {(subdomainchannel == null) && renderRoutes(routes)}
                                        </AuthProvider>
                                    </Router>
                                </SnackbarProvider>
                            </MuiPickersUtilsProvider>
                        </StylesProvider>
                    </ThemeProvider>
                </IntlProvider>
            </StyledEngineProvider>
        );
    }
};

export default App;
